import { ContractPolicy, ContractPolicyEnum } from "../../models/ContractPolicy";
import { baseApiService } from "../baseApiService";

export const contractPolicyApi = baseApiService.injectEndpoints({
    endpoints: (builder) => ({
        getContractPolicy: builder.query<ContractPolicy[], { hospiceOrganizationId: string }>({
            query: ({ hospiceOrganizationId }) => ({
                url: `/v1/hospiceorganization/${hospiceOrganizationId}/contract-policies`,
                method: "GET",
            }),
        }),
        requestContractPolicyAccess: builder.mutation<
            [],
            { hospiceOrganizationId: string; contractPolicyType: ContractPolicyEnum }
        >({
            query: ({ hospiceOrganizationId, contractPolicyType }) => ({
                url: `/v1/hospiceorganization/${hospiceOrganizationId}/contract-policies/${contractPolicyType}/request`,
                method: "POST",
            }),
        }),
    }),
});

export const { useRequestContractPolicyAccessMutation, useGetContractPolicyQuery } = contractPolicyApi;
