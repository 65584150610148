import { Typography, Spin } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import { LeftOutlined } from "@ant-design/icons";
import "../../styles/_patientCard.scss";
import { useGetPatientDetailsQuery, useDropdownOptionsQuery } from "../../features/patient/patientService";
import { RequestConsultationForm } from "./RequestConsultationForm";

const PatientRequestConsultation = () => {
    const { Title } = Typography;
    const navigate = useNavigate();
    const { id: patientId = "" } = useParams();

    const { data: patientDetails, isLoading: patientDetailsLoading } = useGetPatientDetailsQuery({ id: patientId });
    const { data: dropdownOptionsData, isLoading: dropdownOptionsLoading } = useDropdownOptionsQuery();

    const canShow = !dropdownOptionsLoading && !patientDetailsLoading;

    return (
        <div>
            <div className="search-patient">
                <div className="patient-name">
                    <LeftOutlined
                        className="left-arrow mt-4"
                        onClick={() => navigate(`/patient/${patientId}`)}
                        rev={undefined}
                    />
                    <Title level={1} className="title-class">
                        Request Consult
                    </Title>
                </div>
            </div>
            {!canShow && <Spin spinning size="large" />}
            {canShow && patientDetails?.hospiceOrganizationId && (
                <RequestConsultationForm
                    hospiceOrganizationId={patientDetails?.hospiceOrganizationId}
                    consultReasons={dropdownOptionsData?.consultReasons}
                />
            )}
        </div>
    );
};

export default PatientRequestConsultation;
