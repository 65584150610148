import { Button, Checkbox, Col, Form, Input, Modal, Select, Space, Typography, message } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import TextArea from "antd/lib/input/TextArea";
import { useForm } from "antd/lib/form/Form";
import { useRequestConsultationMutation } from "../../features/patient/patientService";
import { useEffect } from "react";
import { FetchBaseQueryError } from "@reduxjs/toolkit/dist/query";
import { PatientConsultationRequest } from "../../models/PatientModels";
import { ExclamationCircleFilled } from "@ant-design/icons";
import {
    useGetContractPolicyQuery,
    useRequestContractPolicyAccessMutation,
} from "../../features/contractPolicy/contractPolicyService";
import { ContractPolicyEnum } from "../../models/ContractPolicy";
import { useGetCommunicationPreferencesQuery } from "../../features/appointment/appointmentService";
import { CommunicationChannelType } from "../../models/CommunicationPreference";

const RequestConsultationForm = ({
    hospiceOrganizationId,
    consultReasons,
}: {
    hospiceOrganizationId: string;
    consultReasons: { [key: string]: string } | undefined;
}) => {
    const MODAL_WIDTH = 450;
    const navigate = useNavigate();
    const { id: patientId = "" } = useParams();

    const [form] = useForm<PatientConsultationRequest>();

    const { data: contractPolicies, isLoading: contractPoliciesLoading } = useGetContractPolicyQuery({
        hospiceOrganizationId,
    });
    const { data } = useGetCommunicationPreferencesQuery();

    const [requestContractPolicyAccess, { isLoading: requestContractPolicyAccessLoading }] =
        useRequestContractPolicyAccessMutation();
    const [requestConsultation, { isLoading: requestConsultationLoading }] = useRequestConsultationMutation();

    const hasContractPolicy =
        !contractPoliciesLoading &&
        contractPolicies?.find((cp) => cp.contractPolicyType === ContractPolicyEnum.ClinicalConsult)?.isEnabled
            ? true
            : false;

    const showRequestAccessModal = () => {
        Modal.confirm({
            width: MODAL_WIDTH,
            title: "Why is this disabled?",
            icon: <ExclamationCircleFilled rev={undefined} />,
            content: (
                <div>
                    Request a Clinical Consult is an Enhanced Clinical service offering. Contact your HospiceChoice Rx
                    Account Manager for details
                </div>
            ),
            okText: "Request Access",
            async onOk() {
                await handleRequestContractPolicyAccess();
            },
        });
    };

    const showRequestConsultModal = () => {
        Modal.confirm({
            width: MODAL_WIDTH,
            title: "Confirm",
            icon: (
                <ExclamationCircleFilled
                    rev={undefined}
                    className="ant-modal-confirm-info ant-modal-confirm-body anticon"
                    style={{ color: "var(--ant-color-info)" }}
                />
            ),
            content: <div>Are you sure you want to Request Consult?</div>,
            okText: "Request Consult",
            async onOk() {
                await handleSubmit();
            },
        });
    };

    const showSuccessModal = () => {
        Modal.success({
            width: MODAL_WIDTH,
            title: "Request Successful",
            content: <div>Thank you for your clinical consult request. Your request was submitted successfully.</div>,
            okText: "Close",
            onOk() {
                navigate(`/patient/${patientId}`);
            },
        });
    };

    const handleRequestContractPolicyAccess = async () => {
        const response = await requestContractPolicyAccess({
            hospiceOrganizationId,
            contractPolicyType: ContractPolicyEnum.ClinicalConsult,
        });

        if ("error" in response) {
            const error = response.error as FetchBaseQueryError;
            const errorMessage = (error.data as { title: string }).title;
            message.error(errorMessage);
        }
    };

    useEffect(() => {
        const emailAddress = data?.find((i) => i.channelType === CommunicationChannelType.Email)?.channelDetails;
        const mobilePhoneNumber = data?.find((i) => i.channelType === CommunicationChannelType.Sms)?.channelDetails;

        form.setFieldsValue({
            mobilePhoneNumber,
            emailAddress,
        });
    }, [data, form]);

    const onFinish = () => showRequestConsultModal();

    const handleSubmit = async () => {
        let values = form.getFieldsValue();
        values = {
            ...values,
            patientIds: [patientId],
            minuteDuration: 0,
        };

        try {
            const response = await requestConsultation({
                req: values,
            });

            if ("error" in response) {
                const error = response.error as FetchBaseQueryError;
                const errorMessage = (error.data as { title: string }).title;
                message.error(errorMessage);
            }

            if ("data" in response) {
                showSuccessModal();
            }
        } catch (error: any) {
            console.error("Error occurred:", error);
        }
    };

    return (
        <Form form={form} onFinish={onFinish} layout="vertical" labelCol={{ prefixCls: "form-item_custom-label" }}>
            <Col span={12}>
                <Form.Item
                    name="consultReason"
                    rules={[
                        {
                            required: true,
                            message: "Reason is required.",
                        },
                    ]}
                    label="Reason"
                    initialValue={(consultReasons && Object.keys(consultReasons)[0]) ?? null}
                >
                    <Select
                        placeholder="Select a reason"
                        className="w-100"
                        options={
                            consultReasons &&
                            Object.keys(consultReasons).map((key) => ({
                                value: key,
                                label: consultReasons[key],
                            }))
                        }
                        allowClear
                    />
                </Form.Item>
                <Form.Item name="isUrgentRequest" valuePropName="checked">
                    <Checkbox>Urgent Request?</Checkbox>
                </Form.Item>
                <Form.Item name="notes">
                    <div className="custom-div">
                        <label className="custom-label">Note (Optional)</label>
                        <TextArea className="w-100" rows={4} placeholder="Enter Note" />
                    </div>
                </Form.Item>
                <Form.Item name="note">
                    <Space direction="vertical">
                        <Typography.Text strong>Your Current NotifIcation Preferences</Typography.Text>
                        <div className="d-flex flex-column gap-3">
                            <Typography.Text>
                                The contact methods below are direct contact information for you according to your
                                notifcation preferences and not a general office number.
                            </Typography.Text>
                            <Typography.Text>
                                If you would prefer to be contacted by your direct line, please update the appropriate
                                number below.
                            </Typography.Text>
                        </div>
                    </Space>
                </Form.Item>
                <Form.Item label="Mobile Phone Number" name="mobilePhoneNumber">
                    <Input placeholder="Enter Mobile Phone Number" className="w-100" />
                </Form.Item>
                <Form.Item label="Email Address" name="emailAddress">
                    <Input placeholder="Enter Email Address" className="w-100" />
                </Form.Item>
                <Form.Item label="Direct Line (if different from mobile number)" name="directLine">
                    <Input placeholder="Enter Direct Phone Number" className="w-100" />
                </Form.Item>
                <Form.Item name="isContactInfoConfirmed" valuePropName="checked">
                    <Checkbox>Contact Information Confirmed?</Checkbox>
                </Form.Item>
            </Col>
            <div className="d-flex gap-4 align-items-center">
                <Form.Item shouldUpdate className="submit" noStyle>
                    {({ getFieldsValue }) => {
                        const {
                            consultReason,
                            isContactInfoConfirmed,
                            mobilePhoneNumber,
                            emailAddress,
                            directLine,
                        }: PatientConsultationRequest = getFieldsValue();

                        const isSubmitDisabled =
                            !hasContractPolicy ||
                            !consultReason ||
                            !isContactInfoConfirmed ||
                            (!mobilePhoneNumber && !emailAddress && !directLine);

                        return (
                            <Button disabled={isSubmitDisabled} type="primary" htmlType="submit">
                                Request Consult
                            </Button>
                        );
                    }}
                </Form.Item>
                {!contractPoliciesLoading && !hasContractPolicy && (
                    <Typography.Text underline onClick={showRequestAccessModal} className="cursor-pointer">
                        Why is this disabled?
                    </Typography.Text>
                )}
            </div>
        </Form>
    );
};

export { RequestConsultationForm };
