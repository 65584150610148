export interface ContractPolicy {
    id: string;
    contractPolicyType: ContractPolicyEnum;
    isEnabled: boolean;
    contractPolicyDisplay: string;
}

export enum ContractPolicyEnum {
    ClinicalConsult = 1,
    NonEmrPolicy = 2,
}
