import { Button, Col, DatePicker, Form, Modal, Select, Spin, Typography, message } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import { LeftOutlined } from "@ant-design/icons";
import TextArea from "antd/lib/input/TextArea";
import { useForm } from "antd/lib/form/Form";
import moment from "moment";
import { RangePickerProps } from "antd/lib/date-picker";
import { useDischargePatientMutation, useDropdownOptionsQuery } from "../../features/patient/patientService";
import { PatientStatusEnum } from "../../models/PatientModels";
import { selectPatientResult } from "../../features/patient/patientSlice";
import { useSelector } from "react-redux";
import { LoadingSpinner } from "../../components/Spinner/LoadingSpinner";
import { useEffect, useState } from "react";
import { FetchBaseQueryError } from "@reduxjs/toolkit/dist/query";

const { Title } = Typography;

export const PatientDischarge = () => {
    const navigate = useNavigate();
    const { id: patientId = "" } = useParams();

    const [form] = useForm();
    let [isModalOpen, setIsModalOpen] = useState(false);

    const [dischargePatient, { isLoading: dischargePending }] = useDischargePatientMutation();
    const { data: dropdownOptionsData, isLoading: dropdownOptionsLoading } = useDropdownOptionsQuery();
    const patient_details = useSelector(selectPatientResult);

    useEffect(() => {
        if (
            [
                PatientStatusEnum.DischargedDeceased,
                PatientStatusEnum.DischargedRevoked,
                PatientStatusEnum.DischargedTransferred,
            ].includes(patient_details?.patientDetails.patientStatus)
        ) {
            navigate(`/patient/${patientId}`);
        }
    }, [patientId, patient_details]);

    const REASON_DEFAULT_VALUE = (dropdownOptionsData && Object.keys(dropdownOptionsData.dischargeReasons)[0]) ?? null;
    const dischargeReasonOptions =
        dropdownOptionsData &&
        Object.keys(dropdownOptionsData.dischargeReasons).map((key) => ({
            value: key,
            label: dropdownOptionsData.dischargeReasons[key],
        }));

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const onFinish = () => {
        setIsModalOpen(true);
    };

    const handleSubmit = async () => {
        let values = form.getFieldsValue();
        values = {
            ...values,
            date: moment(values.date.format("YYYY-MM-DD"), "YYYY-MM-DD"),
        };

        try {
            const response = await dischargePatient({
                id: patientId,
                req: values,
            });

            if ("error" in response) {
                const error = response.error as FetchBaseQueryError;
                const errorMessage = (error.data as { title: string }).title;
                setIsModalOpen(false);
                message.error(errorMessage);
            }

            if ("data" in response) {
                message.success("Patient discharged successfully.");
                navigate(`/patient/${patientId}`);
            }
        } catch (error: any) {
            console.error("Error occurred:", error);
        }
    };

    const disabledDate: RangePickerProps["disabledDate"] = (current) => {
        // Can not select days before today and today
        return current && current > moment().endOf("day");
    };

    return (
        <>
            <div className="search-patient">
                <div className="patient-name">
                    <LeftOutlined
                        className="left-arrow mt-4"
                        onClick={() => navigate(`/patient/${patientId}`)}
                        rev={undefined}
                    />
                    <Title level={1} className="title-class">
                        Patient Discharge
                    </Title>
                    <Title level={5}>Personal Info</Title>
                </div>
            </div>
            {dropdownOptionsLoading && <Spin size="large" />}
            {!dropdownOptionsLoading && (
                <Form
                    form={form}
                    onFinish={onFinish}
                    layout="vertical"
                    labelCol={{ prefixCls: "form-item_custom-label" }}
                >
                    <Col span={12}>
                        <Form.Item
                            name="dischargeReason"
                            rules={[
                                {
                                    required: true,
                                    message: "Reason is required.",
                                },
                            ]}
                            initialValue={REASON_DEFAULT_VALUE}
                            label="Reason"
                        >
                            <Select
                                placeholder="Select a reason"
                                defaultValue={REASON_DEFAULT_VALUE}
                                style={{ width: "100%" }}
                                options={dischargeReasonOptions}
                                allowClear
                            />
                        </Form.Item>
                        <Form.Item
                            label="End date"
                            name="date"
                            initialValue={moment()}
                            rules={[
                                {
                                    required: true,
                                    message: "Please select a discharge date.",
                                },
                            ]}
                        >
                            <DatePicker
                                style={{ width: "100%" }}
                                className="input-field"
                                format="MM-DD-YYYY"
                                disabledDate={disabledDate}
                            />
                        </Form.Item>
                        <Form.Item name="note">
                            <div className="custom-div">
                                <label className="custom-label">Note (Optional)</label>
                                <TextArea style={{ width: "100%" }} rows={4} placeholder="Enter Note" />
                            </div>
                        </Form.Item>
                    </Col>
                    <Button type="primary" htmlType="submit" disabled={dischargePending}>
                        {dischargePending && <LoadingSpinner />}Discharge
                    </Button>
                    <Modal
                        open={isModalOpen}
                        closable={false}
                        footer={[
                            <Button onClick={handleCancel} disabled={dischargePending}>
                                Nevermind
                            </Button>,
                            <Button type="primary" htmlType="submit" onClick={handleSubmit} disabled={dischargePending}>
                                {dischargePending && <LoadingSpinner />}Yes, discharge
                            </Button>,
                        ]}
                    >
                        You are about to discharge this patient.
                    </Modal>
                </Form>
            )}
        </>
    );
};
