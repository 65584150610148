import React, { useEffect } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import NoMatch from './components/Utils/NoMatch';
import ProtectedRoutes from './pages/Auth/ProtectedRoutes';
import Login from './pages/Auth/Login';
import Signup from './pages/Auth/Signup';
import Dashboard from './pages/Dashboard/Dashboard';
import Notifications from './pages/Notifications/Notifications';
import Failures from './pages/Failures/Failures';
import SessionManage from './pages/SessionManagement/SessionManage';
import ResetPassword from './pages/Auth/ResetPassword';
import ForgotPassword from './pages/Auth/ForgotPassword';
import { AccessDenied } from './components/Layout/AccessDenied';
import AppointmentMeeting from './pages/ClinicalConsults/AppointmentMeeting';
import ConfirmEmail from './pages/Auth/ConfirmEmail';
import UnsubscribeEmail from './pages/Auth/UnsubscribeEmail';
import UserNotifications from './pages/Settings/UserNotifications';
import Settings from './pages/Settings/Settings';
import SearchPatient from './pages/SearchPatient/SearchPatient';
import PatientResults from './pages/PatientResults/PatientResults';
import PatientCard from './pages/PatientCard/PatientCard';
import PatientRecord from './pages/PatientRecord/PatientRecord';
import AdmitPatient from './pages/AdmitPatient/AdmitPatient';
import { PatientDischarge } from './pages/PatientDischarge/PatientDischarge';
import ReAdmitPatient from './pages/ReAdminPatient/ReAdmitPatient';
import PatientRequestConsultation from './pages/PatientRequestConsultation/PatientRequestConsultation';

const App = ({ theme }) => {
   useEffect(() => {
      window.process = { ...window.process };
   }, []);

   return (
         <BrowserRouter>
               <Routes>
                  <Route path="/" element={<ProtectedRoutes />}>
                     <Route path="/dashboard" element={<Dashboard />} />
                     <Route path="/admit_patient" element={<AdmitPatient />} />
                     <Route
                        path="/search-patient"
                        element={<SearchPatient />}
                     />
                     <Route
                        path="/patient-results"
                        element={<PatientResults />}
                     />
                     <Route path="/patient/:id/re-admit" element={<ReAdmitPatient />} />
                     <Route path="/patient/:id" element={<PatientCard />} />
                     <Route path="/patient_record/:id" element={<PatientRecord />} />
                     <Route path="/patient-discharge/:id" element={<PatientDischarge />} />
                     <Route path="/patient/:id/clinical-consultation" element={<PatientRequestConsultation />} />
                     <Route path="/account-team">
                        <Route
                           path="notifications"
                           element={<Notifications />}
                        />
                        <Route
                           path="data-integration-failures"
                           element={<Failures />}
                        />
                        <Route
                           path="device-session-management"
                           element={<SessionManage />}
                        />
                     </Route>
                     <Route path="/clinical-team">
                        <Route
                           path="clinical-consults"
                           element={<AppointmentMeeting />}
                        />
                     </Route>
                     <Route path="/settings">
                        <Route index element={<Settings />} />
                        <Route
                           path="notifications"
                           element={<UserNotifications />}
                        />
                     </Route>
                     <Route path="*" element={<NoMatch />} />
                  </Route>
                  <Route path="/login" element={<Login />} />
                  <Route path="/signup" element={<Signup />} />
                  <Route path="/access-denied" element={<AccessDenied />} />
                  <Route
                     path="/account/reset-password"
                     element={<ResetPassword />}
                  />
                  <Route
                     path="/account/forgot-password"
                     element={<ForgotPassword />}
                  />
                  <Route
                     path="/communication/preferences/confirm-email"
                     element={<ConfirmEmail />}
                  />
                  <Route
                     path="/communication/preferences/unsubscribe-email"
                     element={<UnsubscribeEmail />}
                  />
                  <Route path="*" element={<NoMatch />} />
               </Routes>
         </BrowserRouter>
   );
};

export default App;